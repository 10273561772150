import React from 'react';

import { AppProvider } from './AppContext'; // Imports context provider with all state variables

// Components
import SearchResults from './components/searchresults/SearchResults';
import SupportPage from './components/SupportPage';
import HomePage from './components/HomePage';
import Error404Page from './components/Error404Page';
import ScrollToTop from './components/ScrollToTop';
import MyNavbar from './components/MyNavbar';
import Breadcrumbs from './components/Breadcrumbs';
import { PageTracker, RedirectToLastResultsPage } from './components/LastResultsPageFunctions';
import SearchLogic from './components/searchbars/SearchLogic';
import { Sidebar } from './components/catalyst/sidebar';
import { StackedLayout } from './components/catalyst/stacked-layout';

// Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SubmittedPage from './components/SubmittedPage';

// Auth
import AuthGuard from './auth/AuthGuard';

function App() {
	return (
		<AppProvider>
			<Router>
				<PageTracker>
					<ScrollToTop />
					<StackedLayout className="max-h-screen"
						navbar={<MyNavbar />}
						sidebar={<Sidebar>{/* Your sidebar content */}</Sidebar>}
					>
						<div className='mb-2 w-full flex items-center justify-center'>
							<div className='w-11/12'>
								<Breadcrumbs />
							</div>
						</div>
						<AuthGuard>
							<Routes>
								<Route exact path="/" element={<HomePage />} />
								<Route path="/results" element={<RedirectToLastResultsPage />} />
								<Route path="results/:urlMfgName/:urlSN/:urlMN/:urlRQ" element={<SearchLogic>
									{props => <SearchResults {...props} />}
								</SearchLogic>} />
								<Route path="results/mismatch/:urlMfgName/:urlSN/:urlMN/:urlRQ" element={<SupportPage />} />
								<Route path="support" element={<SupportPage />} />
								<Route path="submitted" element={<SubmittedPage />} />
								<Route path="*" element={<Error404Page />} />
							</Routes>
						</AuthGuard>
					</StackedLayout>
				</PageTracker>
			</Router>
		</AppProvider>
	);
}

export default App;
