import { useState, useEffect } from 'react';

// Custom hook to store a state variable in a browser's local storage
// Pass in a key to store the value under (can just use the state variable's name) and a default value
// Returns an array with the local storage state variable and the function to set the state variable
export default function useSessionStorage(key, defaultValue) {
	const [value, setValue] = useState(() => {
		const storedValue = typeof window !== 'undefined' ? sessionStorage.getItem(key) : null;
		return storedValue === null ? defaultValue : JSON.parse(storedValue);
	});

	useEffect(() => {
		const listener = (e) => {
			if (typeof window !== 'undefined' && e.storageArea === sessionStorage && e.key === key) {
				setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
			}
		};
		window.addEventListener('storage', listener);

		return () => {
			window.removeEventListener('storage', listener);
		};
	}, [key, defaultValue]);

	const setValueInSessionStorage = (newValue) => {
		setValue((currentValue) => {
			const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;
			if (typeof window !== 'undefined') sessionStorage.setItem(key, JSON.stringify(result));
			return result;
		});
	};

	return [value, setValueInSessionStorage];
}