//Returns the feedback panel
import { Button } from '../catalyst/button'
import { Alert, AlertTitle, AlertDescription, AlertActions } from '../catalyst/alert';
import { TextField } from '@mui/material';
import React, { useState, useEffect, useContext, useMemo } from "react";
import { ThumbsUp, ThumbsDown } from 'lucide-react';

import { AppContext } from "../../AppContext";


function FeedbackPanel({
	typeOfResult,
	optPartDesc,
	mfgName,
	repPartName,
	ptPartName,
	mfgPartName,
	partDesc,
	imgURL,
	partObsolete,
	feedbackPanelStates,
	handleFeedbackPanelStates
}) {

	// Whether the alert is open for the like/dislike buttons
	const [likeAlertIsOpen, setLikeAlertIsOpen] = useState(false);
	const [dislikeAlertIsOpen, setDislikeAlertIsOpen] = useState(false);
	

	// Renders the feedback panel states to be whatever is stored in feedbackPanelStates
	// (sets like, dislike, and feedbackText to be false, false, and "" if they were undefined)
	// Use useMemo to calculate initialFeedbackState only when ptPartName or feedbackPanelStates change
	const initialFeedbackState = useMemo(() => {
		return feedbackPanelStates[`${mfgPartName}${partDesc}`] || { likeClicked: false, dislikeClicked: false, feedbackText: "" };
	}, [feedbackPanelStates, mfgPartName, partDesc]);

	// States for whether the like/dislike buttons have been clicked and the feedback text
	const [likeClicked, setLikeClicked] = useState(initialFeedbackState.likeClicked);
	const [dislikeClicked, setDislikeClicked] = useState(initialFeedbackState.dislikeClicked);
	const [feedbackText, setFeedbackText] = useState(initialFeedbackState.feedbackText);

	//Updates the dictionary of feedbackPanelStates with the new feedbackPanelStates
	useEffect(() => {
		handleFeedbackPanelStates(mfgPartName, partDesc, { likeClicked, dislikeClicked, feedbackText });
	}, [likeClicked, dislikeClicked, feedbackText, handleFeedbackPanelStates, mfgPartName, partDesc]);


	const { logFeedbackHandler } = useContext(AppContext);


	// Function to dismiss the like/dislike alert and send feedback data
	function handleFeedback(theyClickedLike, feedbackText) {
		// Sets the variables showing what has been clicked
		// and dismisses the alert
		if (theyClickedLike) {
			setLikeClicked(true);
			setDislikeClicked(false);
			setLikeAlertIsOpen(false);
			setDislikeAlertIsOpen(false);
		} else {
			setDislikeClicked(true);
			setLikeClicked(false);
			setDislikeAlertIsOpen(false);
			setLikeAlertIsOpen(false);
		}
		// Logs for this part what category it was under (typeOfResult being rc, snl, manuals, etc.)
		// ptPartName is the actual part number (StockCode)
		// theyClickedLike is a boolean for whether they clicked like or dislike
		// feedbackText is the optional feedback they entered
		logFeedbackHandler(
			typeOfResult,
			optPartDesc,
			mfgName,
			repPartName,
			ptPartName,
			mfgPartName,
			partDesc,
			imgURL,
			partObsolete,
			theyClickedLike,
			feedbackText
		)
	}

	return (
		<div className='flex flex-row justify-evenly w-full mt-4'>
			<div className='flex flex-row w-fit gap-x-4 justify-between' aria-label='feedback'>
				<>
				<Button
					className= 'h-full min-w-fit'
					aria-label="thumbs-up"
					outline
					onClick={() => (likeClicked ? setLikeAlertIsOpen(false) : setLikeAlertIsOpen(true))}
				>
					<ThumbsUp
						strokeWidth={1.5}
						fill={likeClicked ? "black" : 'none'}
						className='dark:invert'
					/>
				</Button>
				<Alert open={likeAlertIsOpen} onClose={setLikeAlertIsOpen}>
					<AlertTitle>Confirmation</AlertTitle>
					<AlertDescription>
						Do you want to send feedback for Part {ptPartName} for {typeOfResult}?
					</AlertDescription>
					<TextField
						label="Optional Feedback"
						multiline
						rows={4}
						variant="outlined"
						value={feedbackText}
						onChange={(e) => setFeedbackText(e.target.value)}
						fullWidth
						className='mt-2'
					/>
					<AlertActions>
					<Button plain onClick={() => setLikeAlertIsOpen(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => handleFeedback(true, (!feedbackText || feedbackText==="") ? " " : feedbackText)}>Submit Like
					</Button>
					</AlertActions>
				</Alert>
				</>

				<>
				<Button
					className= 'h-full min-w-fit'
					aria-label="thumbs-down"
					outline
					onClick={() => (dislikeClicked ? setDislikeAlertIsOpen(false) : setDislikeAlertIsOpen(true))}
				>
					<ThumbsDown
						strokeWidth={1.5}
						fill={dislikeClicked ? "black" : 'none'}
						className='dark:invert'
					/>
				</Button>
				<Alert open={dislikeAlertIsOpen} onClose={setDislikeAlertIsOpen}>
					<AlertTitle>Confirmation</AlertTitle>
					<AlertDescription>
						Do you want to send feedback for Part {ptPartName} for {typeOfResult}?
					</AlertDescription>
					<TextField
						label="Optional Feedback"
						multiline
						rows={4}
						variant="outlined"
						value={feedbackText}
						onChange={(e) => setFeedbackText(e.target.value)}
						fullWidth
						className='mt-2'
					/>
					<AlertActions>
					<Button plain onClick={() => setDislikeAlertIsOpen(false)}>
						Cancel
					</Button>
					<Button
						onClick={() => handleFeedback(false, (!feedbackText || feedbackText==="") ? " " : feedbackText)}>Submit Dislike
					</Button>
					</AlertActions>
				</Alert>
				</>
			</div>
		</div>
	)
}

export default FeedbackPanel;