// SearchLogic.js
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';

// Contains the logic for the search bar
// Component for a search bar is passed in as children so that differently styled search bars can be used
function SearchLogic({ children }) {
	const { setSearchResultsManuals, snToManuals, setSearchResultsBF, queryToBF, snToPartsRC, snToPartsSNL, setSearchResultsPartsRC, setSearchResultsPartsSNL, setIsLoading, isLoading, setKeepSRBlank, logSearchHandler, logResultsHandler, setSearchId, setFeedbackPanelStatesRC, setFeedbackPanelStatesSNL, setFeedbackPanelStatesWeb, setSearchResultsPartsWebsite, snToPartsWebsite } = useContext(AppContext);
	const [serNum, setSerNum] = useState('');
	const [modNum, setModNum] = useState('');
	const [refineQuery, setRefineQuery] = useState('');
	const [manName, setManName] = useState(''); // Manufacturer name in the dropdown menu

	const [submissionError, setSubmissionError] = useState(''); // Prints if both serial number and model number are blank/whitespace

	const navigate = useNavigate();

	//Calls the search APIS
	const doTheSearch = async () => {
		//Resets the results before search begins
		setSearchResultsManuals({});
		setSearchResultsBF({});
		setSearchResultsPartsRC({});
		setSearchResultsPartsSNL({});
		setSearchResultsPartsWebsite({});

		// Resets feedback panel each search
		setFeedbackPanelStatesRC({});
		setFeedbackPanelStatesSNL({});
		setFeedbackPanelStatesWeb({});
	

		//Tells isLoading that there are X calls about to be made
		//The API calls decrement from isLoading but the calling function should add them
		//Prevents race condition where one function adds 1 and removes 1 while the other function hasn't yet added
		setIsLoading((prev) => prev + 5);
	
		// Redirect the app to the search page
		const searchParams = [
			`man=${encodeURIComponent(manName)}`,
			`sn=${encodeURIComponent(serNum)}`,
			`mn=${encodeURIComponent(modNum)}`,
			`refine=${encodeURIComponent(refineQuery)}`
		].filter(Boolean).join('/');
		navigate(`/results/${searchParams}`);

		try {
			//Logs the search, gets the search ID and sets the search ID state variable
			const newSearchId = await logSearchHandler(manName, modNum, serNum, refineQuery);

			setSearchId(newSearchId);

			// 1/4 of the way through the search
			setIsLoading((prev) => prev - 1);

			// Now we run each search endpoint asynchronously.
			// We have to wait for them to each return a promise
			// to indicate they've finished (or errored out) and settled
			// before moving on to log results
			const results = await Promise.allSettled([
				//Searches up manuals and parts based on inputted serial number and/or model number
				snToManuals(serNum, modNum, manName),
				//snToParts(serNum, modNum, manName, refineQuery);
				//queryToBF(query);
				snToPartsRC(serNum, modNum, manName, refineQuery),
				snToPartsSNL(serNum, manName, refineQuery),
				snToPartsWebsite(modNum, manName, refineQuery),
			]);

			// Extract the results from the promises
			const [newManRes, newRCRes, newSNLRes, newWebRes] = results.map(result => 
				result.status === 'fulfilled' ? result.value : result.reason
			);

			// Sets the state variables for the search results
			setSearchResultsManuals(newManRes);
			setSearchResultsPartsRC(newRCRes);
			setSearchResultsPartsSNL(newSNLRes)
			setSearchResultsPartsWebsite(newWebRes)

			console.log("newManRes", newManRes);
			console.log("newRCRes", newRCRes);
			console.log("newSNLRes", newSNLRes);
			console.log("newWebRes", newWebRes);

			//Logs the search results
			//Makes it await because then it won't start until the previous async functions are done
			logResultsHandler(newSearchId, newRCRes?.casesList, newRCRes?.parts, newSNLRes, newWebRes, newManRes);

		} catch (error) {
			console.log("An error occurred while searching:", error);
			setIsLoading((prev) => prev - 1);
		} finally {
			//Tells isLoading that there are X less calls to be made
			setIsLoading((prev) => prev - 4);
		}

	};

	//Activates the function to input serial number and get back manuals
	const handleSearch = async (e = null) => {
		if (e) {
			e.preventDefault();
		}

		//Doesn't search if both serial number and model number are blank/whitespace
		//Also doesn't search if there is already an API call in progress
		if (
			((serNum.replace(/\s+/g, '') === '') && (modNum.replace(/\s+/g, '') === '')) ||
			isLoading > 0
		) {
			// Sets error message if both serial number and model number are blank/whitespace
			if ((serNum.replace(/\s+/g, '') === '') && (modNum.replace(/\s+/g, '') === '')) {
				setSubmissionError("Please enter a value for Serial Number and/or Model");
			}
			return;
		} else {
			setSubmissionError("");
		}

		//Search results should show up whether it's cards or "No results"
		setKeepSRBlank(false);

		//Calls the manuals and parts API endpoints and logs them
		doTheSearch();
	};

	//Updates change to input bar for serial number
	const handleSearchSNChange = (e) => {
		setSerNum(e.target.value.toUpperCase());
	};

	//Updates change to input bar for model number
	const handleSearchMNChange = (e) => {
		setModNum(e.target.value.toUpperCase());
	};

	//Updates change to input bar for refine query
	const handleSearchRefineQueryChange = (e) => {
		setRefineQuery(e.target.value);
	};

	//Returns rendered version of whatever search bar is passed in as children
	return children({ serNum, modNum, manName, refineQuery, handleSearchSNChange, handleSearchMNChange, setManName, setSerNum, setModNum, setRefineQuery, handleSearchRefineQueryChange, handleSearch, submissionError });

	// /* *** NOTE Allows for app to search after every key press *** */
	// useEffect(() => {
	//	 handleSearch();
	// }, [query]);
};

export default SearchLogic;
