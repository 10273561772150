import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		// Scroll the window to the top
		window.scrollTo(0, 0);

		// Scroll all scrollable divs to the top
		const scrollableDivs = document.querySelectorAll('.scrollable-div');
		scrollableDivs.forEach(div => {
			div.scrollTo(0, 0);
		});
	}, [pathname]);

	return null;
}

export default ScrollToTop;
