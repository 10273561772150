import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import Loading from '../Loading';
import SearchResultColumns from './SearchResultColumns'


function SearchResults ({
	serNum, 
	modNum, 
	manName, 
	refineQuery,
	setManName, 
	setSerNum, 
	setModNum, 
	setRefineQuery,
	handleSearch
}) {
	const { isLoading, keepSRBlank } = useContext(AppContext);

	//Params from the URL
	// urlMfgName = Manufacturer Name
	// urlSN = Serial Number
	// urlMN = Model Number
	// urlRQ = Refine Query (phrase used to search for parts)
	const { urlMfgName, urlSN, urlMN, urlRQ } = useParams();

	// Cleans up the passed in parameters with the split function since 
	// man=, mn_sn=, and query= are passed in the URL as well
	// Does try/catch to decode the URI if it's encoded and if it's not, just renders the raw value
	useEffect(() => {
		try {
			setManName(decodeURIComponent(urlMfgName.split("=")[1]));
		} catch {
			setManName(urlMfgName.split("=")[1]);
		}

		try {
			setSerNum(decodeURIComponent(urlSN.split("=")[1]));
		} catch {
			setSerNum(urlSN.split("=")[1]);
		}

		try {
			setModNum(decodeURIComponent(urlMN.split("=")[1]));
		} catch {
			setModNum(urlMN.split("=")[1]);
		}

		try {
			setRefineQuery(decodeURIComponent(urlRQ.split("=")[1]));
		} catch {
			setRefineQuery(urlRQ.split("=")[1]);
		}
	}, [urlMfgName, urlSN, urlMN, urlRQ, setManName, setSerNum, setModNum, setRefineQuery]);

	useEffect(() => {
		if (serNum || modNum || manName || refineQuery) {
			handleSearch();
		}
		}, [serNum, modNum, manName, refineQuery]);
		return (
			<div className='w-full'>
				{/* Loading component shows whenever isLoading is > 0 (aka at least 1 API fetch call running) */}
				{(isLoading > 0) && <Loading />}
			
				{/* Shows search results only if no API calls are being made and
					there is no directive to keepSRBlank */}
				{((isLoading === 0) && !keepSRBlank) &&
					<SearchResultColumns 
					urlMfgName={manName}
					urlSN={serNum}
					urlMN={modNum}
					urlRQ={refineQuery}
				/>
				}
			</div>
		)
	};
	
	export default SearchResults;