// Copy of combobox component that returns a combobox of list of values passed in as props

import React, { useState } from 'react';


// Components needed for ShadCN Combobox component
import { Check, ChevronsUpDown } from "lucide-react"
 
import { cn } from "../lib/utils"
import { Button } from "./ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./ui/popover"

// valuesList is the list of selectable values
// varToSet is the state variable to set whenever value is selected
// setVarToSet is the function to set the state variable
function ComboBox({valuesList, varToSet, setVarToSet}) {
	const [open, setOpen] = useState(false);

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
			<Button
				variant="outline"
				role="combobox"
				aria-expanded={open}
				className="w-full h-full justify-between"
			>
				{varToSet
				? valuesList.find((manufacturer) => manufacturer.value === varToSet)?.label
				: "Manufacturer"}
				<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
			</Button>
			</PopoverTrigger>
			<PopoverContent className="w-full h-full p-0">
			<Command>
				<CommandInput placeholder="Search manufacturer..." />
				<CommandList>
				<CommandEmpty>No manufacturer found.</CommandEmpty>
				<CommandGroup>
					{valuesList.map((manufacturer) => (
					<CommandItem
						key={manufacturer.value}
						value={manufacturer.value}
						onSelect={(currentValue) => {
						setVarToSet(currentValue === varToSet ? "" : currentValue)
						setOpen(false)
						}}
					>
						<Check
						className={cn(
							"mr-2 h-4 w-4",
							varToSet === manufacturer.value ? "opacity-100" : "opacity-0"
						)}
						/>
						{manufacturer.label}
					</CommandItem>
					))}
				</CommandGroup>
				</CommandList>
			</Command>
			</PopoverContent>
		</Popover>
	);
}

export default ComboBox;