import axios from 'axios';

// search API
export const api = axios.create({
	baseURL: process.env.REACT_APP_SEARCH_BACKEND_SQL_URL,
	// baseURL: 'http://localhost:8000',
});

// testing logging SQL API
export const loggingAPI = axios.create({
	baseURL: process.env.REACT_APP_FEEDBACK_BACKEND_URL,
	// baseURL: 'http://localhost:8080',
});


// eslint-disable-next-line
export const fetchAllDatabases = async (setDatabases, setIsLoading) => {
	try {
		const response = await api.get('/api/databases/');
		if(response.data.status_code === 200) {
		setDatabases(response.data.databases);
		} else {
		setDatabases({});
		};

	} catch (error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.error('Error response:', error.response);
		} else if (error.request) {
			// The request was made but no response was received
			console.error('Error request:', error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error message:', error.message);
		}
	} finally {
		setIsLoading(prev => prev - 1);
	}
};
// eslint-disable-next-line
export const fetchDatabase = async (dbName, setDatabases, setIsLoading) => {
	try {
		const response = await api.get(`/api/databases/${dbName}`);
		if(response.data.status_code === 200) {
		setDatabases(response.data.databases);
		} else {
		setDatabases({});
		};
	} catch (error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.error('Error response:', error.response);
		} else if (error.request) {
			// The request was made but no response was received
			console.error('Error request:', error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.error('Error message:', error.message);
		}
	} finally {
		setIsLoading(prev => prev - 1);
	}
};