// SearchBarHome.js
// Search bar for the home page
import React from 'react';
import MyDropdown from '../MyDropdown';
import { FieldGroup, Fieldset, Label } from '../catalyst/fieldset'
import { Button } from '../catalyst/button'
import { Input } from '../catalyst/input';

function SearchBarHome ({
	serNum,
	modNum,
	manName,
	refineQuery,
	handleSearchSNChange,
	handleSearchMNChange,
	setManName,
	handleSearchRefineQueryChange,
	handleSearch,
	submissionError,
}) {
	return(
		<Fieldset>
			<form onSubmit={handleSearch}>
				<FieldGroup>
					<div className='flex flex-col items-center justify-center gap-y-4'>
						<div className='w-5/6 space-y-2'>
							<div className='flex flex-row justify-center items-center gap-x-2 w-full'>
								<div className='w-1/3'>
									<div className='w-full'>
										<MyDropdown manName={manName} setManName={setManName} />
									</div>
								</div>
								<div className='flex flex-row gap-x-2 w-2/3 h-fit'>
									<Input
										aria-label="Search Bar"
										name="serial_number"
										value={serNum}
										onChange={handleSearchSNChange}
										placeholder="Serial Number"
									/>
									<Input
										aria-label="Search Bar"
										name="model_number"
										value={modNum}
										onChange={handleSearchMNChange}
										placeholder="Enter Model"
									/>
								</div>
							</div>

							<div className='w-full text-xs text-center text-red-600'>{submissionError}</div>

							<div className='flex items-center justify-center w-full'>
								<div className="w-full">
									<Input
										aria-label="Search Bar"
										name="part_name"
										value={refineQuery}
										onChange={handleSearchRefineQueryChange}
										placeholder="Part Description"
									/>
								</div>
							</div>

							{/* Put button in a div to be affected by space-y */}
							<div>
								<Button aria-label="Search" type="submit" onClick={handleSearch} className='bg-black w-full max-h-10 mt-1'>
											Search
								</Button>
							</div>
						</div>
					</div>
				</FieldGroup>
			</form>
		</Fieldset>
	);
};

export default SearchBarHome;
