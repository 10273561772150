import React from 'react';
import { useLocation } from 'react-router-dom';
import Error404Page from './Error404Page';
import { Button } from './catalyst/button';


function SubmittedPage() {
	const location = useLocation();

	// Checks that location isn't empty
	if (!location.state) {
		return <Error404Page />;
	}

	// Pulls the form values from the location state
	// If the values don't exist, sets them to empty strings
	const { formValues = {}, ticketID = '' } = location.state || {};

	// Converts the JSON keys to more readable names
	const formKeyNames = {
		"manName" : "Manufacturer",
		"typeOfUnit" : "Type of Unit",
		"modelNum" : "Model Number",
		"serialNum" : "Serial Number",
		"partsNeeded" : "Parts Needed",
		"accountNum" : "Account Number",
		"contactName": "Contact Name",
		"cellNum" : "Cell Number",
		"email" : "Email",
		"prefContact" : "Preferred Contact",
	};


	return (
		<div>
			<div className="min-h-screen flex flex-col items-center w-full">
				<div className='w-11/12'>
					<h1 className='text-3xl font-bold mb-4'>Form Submitted Successfully</h1>
					<p>Thank you for submitting the ticket. It is currently being processed. Here are your details:</p>
					<div>
						<div className="max-w-xl mt-4 mx-auto bg-white shadow-md rounded-lg overflow-hidden">
							<div className="p-6">
								<div className='font-bold text-gray-900 text-center mb-2'>Ticket {ticketID}:</div>
								{Object.entries(formValues).map(([key, value]) => (
									<div key={key} className="flex mb-2">
										<div className="w-1/2 font-semibold text-gray-700">{formKeyNames[key]}:</div>
										<div className="w-1/2 text-gray-900 text-right">{value}</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
									
				<Button className='mt-10 w-1/6 h-10' href='/'>Go Back To Home</Button>
			</div>
			
		</div>
	);
}

export default SubmittedPage;
