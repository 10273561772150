// The card to show for parts
import { TableCell, TableRow } from '../catalyst/table';
import FeedbackPanel from './FeedbackPanel';

import { useContext } from "react";

import { AppContext } from "../../AppContext";


function SearchResultPartsRCEntry({
	tableRowNum,	//the row number in the table (aka key)
	optPartDesc,	//optimized Part Description
	mfgName,			//Manufacturer Name
	repPartName,	//replacedBy (aka most up to date) part name
	ptPartName,	 //part name by PartsTown (if repPartName has a name, this is outdated) (contains both manufacturer code + Parts Town's mfg code)
	mfgPartName,	//part name by manufacturer (contains only the part number without the mfg prefix)
	partDesc,		 //part Description
	imgURL,			 //image URL
	partObsolete, //Y or N to indicate if the part is obsolete
	rcNum,				//Number of times the part is used in the list of resolved cases
	partType,		 //Type of part (rc, snl, manuals, etc.)
	}) {

	const { feedbackPanelStatesRC, handleFeedbackPanelStatesRC } = useContext(AppContext);

	//Adding ?thumb&image will make the .view file render and spin once
	//Adding just ?thumb will make the .view file render as a static image
	imgURL = imgURL + "?thumb"

	const partTitle = mfgName + " " + mfgPartName + " " + (
		// Chooses optPartDesc if it's not empty, otherwise chooses partDesc
		// If optPartDesc is "NOT IN WEBSITE", it will choose partDesc if it's not empty and not "NOT IN WEBSITE"
		optPartDesc.trim() === "" 
		? (partDesc.trim() === ""
			? optPartDesc
			: partDesc
		) 
		// If optPartDesc is "NOT IN WEBSITE", it will choose partDesc if it's a non-filler/non-empty string
		// Otherwise, it will choose optPartDesc
		: (optPartDesc.trim() === "NOT IN WEBSITE" 
			? (partDesc.trim() !== "" && partDesc.trim() !== "NOT IN WEBSITE"
				? partDesc
				: optPartDesc
			) 
			: optPartDesc
		)
	);
	
	const partLink = `https://www.partstown.com/${mfgName.toLowerCase()}/${((repPartName && repPartName !== " ") ? repPartName.toLowerCase() : ptPartName.toLowerCase())}`;
	

	// Returns the disabled table if obsolete is equal to "NOT IN WEBSITE"
	if (partObsolete === "NOT IN WEBSITE") {
		const noWebsiteDataURL = "https://www.partstown.com/images/partstownContentCatalog/source/no-results-banner_285x314.jpg";
		return (
			// search-result-entry with a disabled class
			<TableRow key={tableRowNum}>
				{/* Note that the card doesn't click anywhere if there's no link data */}
				<TableCell className='grid grid-cols-7 gap-x-4'title={partLink}>
						<div className='col-span-2 flex justify-center w-full h-full'>
							<img src={noWebsiteDataURL} alt={noWebsiteDataURL} className="rounded-md object-contain max-w-full max-h-full" />
						</div>
						<div className='col-span-5 flex flex-col'>
							<div className='max-w-full'>
								<h3 className="text-md font-bold text-left mb-2 text-wrap">{((partDesc !== "NOT IN WEBSITE") ? partTitle : (mfgName + " " + mfgPartName))}</h3>
								<div className="flex flex-col">
									<span className="text-sm">Mfr Part #: {mfgPartName}</span>
									<span className="text-sm">Parts Town #: {ptPartName}</span>
								</div>
							</div>
							<div className="w-fit mt-3 bg-gray-200 text-black px-4 py-1 rounded-md text-xs font-medium dark:bg-gray-200 dark:text-black">
								Part information not on website
							</div>
						</div>
						<div className='col-span-7 mt-3 w-full flex justify-center bg-green-100 rounded-md text-xs py-1'>
							{rcNum} time{rcNum === 1 ? "" : "s"} the part was {mfgPartName}
						</div>
					<div
						className='col-span-7 w-full flex justify-center'
						onClick={(e) => e.stopPropagation()} // This stops the click event from bubbling up to the TableCell
					>
						<FeedbackPanel
							typeOfResult={partType}
							optPartDesc={optPartDesc}
							mfgName={mfgName}
							repPartName={repPartName}
							ptPartName={ptPartName}
							mfgPartName={mfgPartName}
							partDesc={partDesc}
							imgURL={imgURL}
							partObsolete={partObsolete}
							feedbackPanelStates={feedbackPanelStatesRC}
							handleFeedbackPanelStates={handleFeedbackPanelStatesRC}
						/>
					</div>
				</TableCell>
			</TableRow>
		);
	// Returns the table with the part information if obsolete is an actual value
	} else {
		return (
			// search-result-entry
			<TableRow key={tableRowNum}>
				<TableCell className='cursor-pointer grid grid-cols-7 gap-x-4'title={partLink} onClick={() => {window.open(partLink)}}>
						<div className='col-span-2 flex justify-center w-full h-full'>
							<img src={imgURL} alt={imgURL} className="rounded-md object-contain max-w-full max-h-full" />
						</div>
						<div className='col-span-5 cursor-pointer flex flex-col'>
							<div className='max-w-full'>
								<h3 className="text-md font-bold text-left mb-2 text-wrap">{partTitle}</h3>
								<div className="flex flex-col">
									<span className="text-sm">Mfr Part #: {mfgPartName}</span>
									<span className="text-sm">Parts Town #: {ptPartName}</span>
								</div>
							</div>
							{/* partObsolete renders green or red box depending on what the field contains: "Y" or "N" */}
							{(partObsolete === "N") ? (
								<div className="w-fit mt-3 bg-lime-100 text-black px-4 py-1 rounded-md text-xs font-medium dark:bg-lime-100 dark:text-black">
									In Use
								</div>
							): (
								// Will render a red box with the replaced part name if the part is obsolete (also accounts for if there is no replaced part name)
								<div className="w-fit mt-3 bg-red-200 text-black px-4 py-1 rounded-md text-xs font-medium dark:bg-red-100 dark:text-black">
									Obsolete, {(repPartName && repPartName !== " ") ? `replaced by ${repPartName}` : "no longer available"}
								</div>
							)}
						</div>
						<div className='col-span-7 mt-3 w-full flex justify-center bg-green-100 rounded-md text-xs py-1'>
							{rcNum} time{rcNum === 1 ? "" : "s"} the part was {ptPartName}
						</div>
					<div
						className='col-span-7 w-full flex justify-center'
						onClick={(e) => e.stopPropagation()} // This stops the click event from bubbling up to the TableCell
					>
						<FeedbackPanel
							typeOfResult={partType}
							optPartDesc={optPartDesc}
							mfgName={mfgName}
							repPartName={repPartName}
							ptPartName={ptPartName}
							mfgPartName={mfgPartName}
							partDesc={partDesc}
							imgURL={imgURL}
							partObsolete={partObsolete}
							feedbackPanelStates={feedbackPanelStatesRC}
							handleFeedbackPanelStates={handleFeedbackPanelStatesRC}
						/>
					</div>
				</TableCell>
			</TableRow>
		);
	};
};

export default SearchResultPartsRCEntry;