//Returns the pagination section
import { Pagination, PaginationGap, PaginationList, PaginationNext, PaginationPage, PaginationPrevious } from "../catalyst/pagination";
import React, { useState, useEffect } from "react";

function PaginationSection({
	totalItems,
	currentPage,
	itemsPerPage,
	onPageChange,
}) {

	//Custom function to emulate range() of Python but returns an array
	const range = (start, end) => {
		let length = end - start + 1;
		/*
			Create an array of certain length and set the elements within it from
		  start value to end value.
		*/
		return Array.from({ length }, (_, idx) => idx + start);
	};

	let totalPages = Math.ceil(totalItems / itemsPerPage);

	//Creates the pages array that should be rendered, with ... included
	//(due to useEffect this is also a way to find out what the new total number of pages is)
	//Will also update currentPage if the new currentPage is out of bounds of the new totalPages
	const [gapsPages, setGapsPages] = useState([]);
	useEffect(() => {		
		// Render all pages if there are <= 5 pages
		if (totalPages <= 5) {
			setGapsPages(range(1, totalPages));

		} else {
			// Always have page 1
			let retList = [1]; //List of pages to be rendered


			//Do dots between 1 and currentPage if currentPage > 3
			if (currentPage > 3) {
				retList.push("pagegap");
			}

			// Show pages around currentPage
			const startPage = Math.max(2, currentPage - 1);
			const endPage = Math.min(totalPages - 1, currentPage + 1);

			for (let i = startPage; i <= endPage; i++) {
				retList.push(i);
			}

			//Do dots between currentPage and totalPages if currentPage < totalPages - 2
			if (currentPage < totalPages - 2) {
				retList.push("pagegap");
			}

			// Always show last page
			retList.push(totalPages);

			setGapsPages(retList);
		}

		
		// This prevents the current page from constantly changing to 1
		// by the last if statement here
		if (totalPages <= 0) {
			totalPages = 1;
			currentPage = 1;

		// If the current page becomes out of bounds of totalPages, set it to totalPages
		} else if (currentPage > totalPages) {
			onPageChange(totalPages);

		// Doesn't set currentPage to 1 even if it's out of bounds if totalPages is 0 since that
		// sets up an endless loop
		// Otherwise, will bring the page back up to the first page
		} else if (currentPage < 1 && totalPages > 0) {
			onPageChange(1);
		}

	}, [currentPage, totalPages, onPageChange]);

	// Function to convert pages array to Catalyst components
	function convertPageArrToComponents(pageNum, index) {
		if (pageNum === "pagegap") {
			return <PaginationGap key={index}/>;
		} else {
			return(
				<PaginationPage
					key={index}
					page={pageNum}
					{...(currentPage === pageNum && { current: true })}
					type='button'
					clickFunc={() => onPageChange(pageNum)}
					className='cursor-pointer'
				>
					{pageNum}
				</PaginationPage>
			);
		}
	}

	return (
		<Pagination>
			<PaginationPrevious
				clickFunc={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
				className='cursor-pointer'
				{...(currentPage === 1 || totalPages <= 1) && { shouldDisable: true }}
			>
				Previous
			</PaginationPrevious>
			<PaginationList>
				{gapsPages.map((value, index) => convertPageArrToComponents(value, index))}
			</PaginationList>
			<PaginationNext
				clickFunc={() => onPageChange((currentPage < totalPages) ? currentPage + 1 : totalPages)}
				className='cursor-pointer'
				{...(currentPage === totalPages || totalPages <= 1) && { shouldDisable: true }}
			>
				Next
			</PaginationNext>
		</Pagination>
	)
}

export default PaginationSection;